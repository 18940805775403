import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import pic from "../../images/chairmen.jpeg";
import Navigation from "../Navigation/Navigation";
import "./style.css";
const Management = () => {
  return (
    <div >
      <Navigation/>
      <Container  style={{fontSize:"18px"}} className="mt-3">
      <h1 class='fs-5' className="text-left te border solid bg-warning border-2 bg-rounded  m-3" > Leadership Team: </h1>
        <Row xs={0} className="border-primary">  
          <Col md={4} sm={3} lg={3}>
            <img className="pic" src={pic} alt="" />
            <p  className="text-center m-3 fs-5">Social Connect</p>
            <div className="icon m-2">
                  {/* <a  className="p-2" target="_blank" href="">
                  <i class="fa-brands fa-facebook fa-lg"></i>
                  </a> */}
                  <a className="m-2" target="_blank" href="https://www.linkedin.com/in/mohammad-abdullah-saif-0221314b/">
                  <i class="fa-brands fa-linkedin fa-2xl"></i>
                  </a>
            </div>
          </Col>
          <Col md={8} sm={9} lg={9}>
            <h2 class='fs-5' className="">Engr. Mohammad Abdullah</h2>
            <h6 class='fs-5' style={{color:"#000099"}}>Chairman,Concrete Infrastructure Engineering Co.Ltd(CIEL).</h6>
            <p>
              Engr.Mohadmmad Abdullah was completed his MBA from Brock
              University,St.Catharines,Ontario,Canada in 2018.He did his B.SC.
              In Civil Engineering from The University of Asia Pacific in 2008
              and his major was in Structural designing, regarding the
              structure's resistance against earthquake. Based on the thesis, a
              paper was published in the workshop of the Bangladesh Earthquake
              Society. In August of the same year, he joined Structural
              Engineering Ltd as Director. He has attended quality conventions
              as well as educational conferences in Japan & China. In 2012 Engr.
              Abdullah participated in a course titled ‘Leadership in Global
              Business Management, in Japan, organized by HIDA.
              Now he also Deputy Managing director of The structural engineers Ltd SEL
            </p>
            <p>
              {" "}
              Now Engr. Abdullah has taken the new position of Chairman for
              Concrete Infrastructure Engineering Co.Ltd(CIEL). Under his
              leadership, CIEL has created an advanced business in the
              construction sector.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Management;
