import React from 'react';
import { Container, Row } from 'react-bootstrap';
import About from '../About/About';
import Contact from '../Contact/Contact';
import styled from 'styled-components';
import Navigation from '../Navigation/Navigation';
import Awards from '../Awards/Awards';
import MajorProject from '../MajorProject/MajorProjects';

const Home = () => {
    return (
        <Wrapper>
            <Row style={{ position: 'sticky', top: '0', zIndex: 999 }} className="m-auto">
            <Navigation></Navigation>
            </Row >
        <div id='home'>
            <Container>
                
                <About></About>
                <MajorProject></MajorProject>
                {/* <Project></Project> */}
                <Awards></Awards>
                <Contact></Contact>
            </Container>
        </div>
        </Wrapper>
    );
};
const Wrapper = styled.div`
/* .overflow-section{
    height: calc( 100vh - 95px );
    overflow:auto;
} */
`;

export default Home;