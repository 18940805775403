import AOS from "aos";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import About from "./About";

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div  id="contact">
      <Wrapper  className="mt-5" >
      <Form >
        <Row>
          <Col
            className="pe-5"
            data-aos="fade-right"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="100"
            data-aos-duration="1300"
          >
            <h1 className=" mb-4" style={{ fontWeight: 500, fontSize: "33px" }}>
              CONTACT US
            </h1>
            <div>
              <Input placeholder="Name" />
            </div>
            <div>
              <Input placeholder="Email" />
            </div>
            <div>
              <Input placeholder="Phone Number" />
            </div>
            <div>
              <TextArea
                className="mt-5"
                id="w3review"
                name="w3review"
              ></TextArea>
            </div>
            <div>
              <button className="my-4" type="submit">
                Submit
              </button>
            </div>
          </Col>
          <Col
            md={4}
            data-aos="fade-left"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="100"
            data-aos-duration="1300"
            className="px-4 py-5 "
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <About />
          </Col>
        </Row>
      </Form>
    </Wrapper>
    </div>
  
  );
};
const Wrapper = styled.div`
  overflow: hidden;
  button {
    font-weight: 500;
    color: #045fa6;
    font-size: 18px;
    background: transparent;
    border: none;
    padding: 7px 13px;
    /* border-left:4px solid #045FA6; */
    outline: none;
    z-index: 1;
    position: relative;
    transition: 0.4s;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      background-color: #045fa6;
      transition: 0.4s;
    }
    &:hover {
      color: #fff !important;

      &:before {
        width: 100%;
      }
    }
  }
`;
const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #b8b0b4;
  padding: 7px;
  outline: none;
  margin: 7px 0;

  &:focus {
    border-bottom: 1px solid #000000;
  }
`;
const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #b8b0b4;
  padding: 7px;
  margin: 7px 0;
  outline: none;
  min-height: 220px;
  &:focus {
    border: 1px solid #000000;
  }
`;

export default Contact;
