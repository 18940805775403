import React from 'react';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
const Map = () => {
  const position = [23.7733249,90.4112447];

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
  return (
    <>
      <MapContainer className='w-100 h-100 m-auto'  center={position} zoom={13} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; Concrete Infrastructure Engineering Co.,Ltd '
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={position}>
    <Popup>
      <p style={{ fontSize: '12px', color: '#035EA5', fontWeight: 500 }} className='mb-2'>Concrete Infrastructure Engineering Co.,Ltd</p>
      <p style={{ fontSize: '12px' }} className='p-0 my-0' >Ga-109, Gulshan link road, </p> 
      <p style={{ fontSize: '12px' }} className='p-0 my-1' > Dhaka-1212</p>
    </Popup>
  </Marker>
</MapContainer>
      </>
  );
};

export default Map;