import './App.css';
import Home from './components/Home/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Management from './components/Management/Management';
import Awards from './components/Awards/Awards';
import MajorProject from './components/MajorProject/MajorProjects';
import About from './components/About/About';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
          <Home></Home>
          </Route>
          <Route path="/manage">
            <Management/>
          </Route>
           <Route path="/home">
            <Home></Home>
          </Route>
           <Route path="/project">
            <MajorProject/>
          </Route>
           <Route path="/awards">
            <Awards/>
          </Route>
           <Route path="/about">
            <About/>
          </Route>
           {/* <Route path="/about">
            <Contact/>
          </Route> */}
          
        </Switch>
      </Router>
    </div>
  );
}

export default App;
