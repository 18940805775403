import React from "react";
// import { Link } from "react-scroll";
import { Nav, NavLink, Navbar } from "react-bootstrap";
import Logo from "../../images/header-logo.jpeg";
import styled from "styled-components";
import {  NavLink as Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navigation = () => {
  return (
    <NavWrapper className=" text-black shadow-sm bg-white sticky-top" style={{fontSize:"21px"}}>
      <Navbar className="px-1 py-2" collapseOnSelect expand="lg md sm">
        <Link offset={-150} to="/home#home" as={HashLink}>
          <img
            style={{ width: "auto", height: "60px" }}
            className="ml-1 w-100"
            src={Logo}
            alt=""
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navs-parent">
           <Nav.Link
           as={HashLink}
              offset={-150}
              style={{ cursor: "pointer" }}
              to="/home#home"
              className="me-4 ms-5 text-primary my-2 "
            >
              Home
            </Nav.Link>
            <Nav.Link  offset={-150}  to="/home#about" as={HashLink} className="mx-4 my-2">
              About
            </Nav.Link>
           < Nav.Link>
           </Nav.Link>
           <Nav.Link to="/home#project"  as={HashLink} offset={-150}   className="mx-4 my-2">
              Projects
            </Nav.Link>  
            <NavLink  to="/home#awards" as={HashLink}  offset={-150} className="mx-4 my-2">
              Awards
            </NavLink>
      
            
            {/* <Nav.Link  className='text-white' to="/management">My Orders</Nav.Link> */}
            <Nav.Link offset={-150} as={HashLink}  to="/home#contact" className="mx-4 my-2">
              Contact
            </Nav.Link>
            {/* <Nav.Link>
            <Link exact to="contact" offset={-150} activeClass="active-class" spy={true} className="mx-4 my-2">
              Contact
            </Link>
            </Nav.Link> */}
            <Nav.Link as={HashLink} to="/manage" offset={-150} className="mx-4  my-2 ">
              Management
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavWrapper>
  );
  //     return (
  //       <NavWrapper className='shadow-sm bg-white '>
  //             <Navbar style={{ backgroundColor: 'ligh' }} bg="ligh" variant="light">
  //             <img style={{width:'450px', height:'67px'}} className='mx-auto' src={Logo} alt="" />
  //     <Container>

  //     <Nav className="me-auto navs-parent">
  //       <Link offset={-100} style={{ cursor: 'pointer' }} to='home' className="me-4 ms-5 my-2">Home</Link>
  //       <Link offset={50} to='home' className="mx-4 my-2">About</Link>
  //       <Link  offset={50} to='home' className="mx-4 my-2">Management</Link>
  //       <Link offset={50} to='contact' className="mx-4 my-2">Contact</Link>
  //     </Nav>
  //     </Container>
  //   </Navbar>
  //   </NavWrapper>
  //     );
};
const NavWrapper = styled.div`
  padding: 5px 0 12px 89px;
  position: sticky;
  top: 0;
  min-height: 95px;

  @media screen and (max-width: 991px) {
    .navs-parent {
      & a {
        margin: 4px 0 4px 0 !important;
      }
    }
  }
  a {
    text-decoration: none;
    color: #231f20;
    font-weight: 400;
    cursor: pointer;
  }
  /* padding-x:10px; */
`;

export default Navigation;
