import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import pic from '../../images/awards1.jpg';
import './style.css';  // Import your CSS file for additional styling


const Awards = () => {
    return (
        <>
        {/* <Navigation/> */}
        <Container  id='awards' >
            <h1 className="awards-subtitle text-center m-4">Outstanding Achievements</h1>
            <div className="awards-container">
                <Row>
                    <Col md={8}>
                        <img src={pic} alt="" className="awards-image" />
                    </Col>
                    <Col md={4}>
                        <div className="awards-details m-auto awards-title m-4">                           
                        <p  className="ad-description mt-5  ">
                            "কনক্রিট ইনফ্রাস্ট্রাকচার ইঞ্জিনিয়ারিং কো লিমিটেড" এর
                            চেয়ারম্যান ইঞ্জিনিয়ার মোহাম্মদ আব্দুল্লাহ, ২০২২ সালে
                            ইঞ্জিনিয়ারিং ক্যাটাগরিতে সর্বোচ্চ করদাতা হিসেবে
                             জাতীয় রাজস্ব বোর্ড (NBR) থেকে একটি পুরস্কার অর্জন করেছে। আমরা গর্বিত"
                        </p>
                        {/* <p className="ad-footer">
                            আমাদের সাথে থাকার জন্য আপনাদের ধন্যবাদ জানাই।
                        </p> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
};

export default Awards;
