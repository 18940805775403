import React from "react";
import { Col, Row } from "react-bootstrap";
import Map from "./Map";

const About = () => {
  return (
    <Row  style={{ margin: 0 }}>
      <h6 style={{ textAlign: "justify" }}>
        Concrete Infrastructure Engineering Co,.Ltd
      </h6>
      <label
        style={{
          textAlign: "justify",
          fontWeight: 500,
          textDecoration: "underline",
        }}
        className="mt-2 mb-1"
      >
        Registered Address :
      </label>
      <Col md={12}>
        <p className="p-0 my-0" style={{ textAlign: "justify" }}>
        Ga-109, Gulshan link road,
        </p>
        <p className="p-0 my-1" style={{ textAlign: "justify" }}>
          Dhaka-1212
        </p>
      </Col>
      <Col
        md={12}
        style={{
          overflow: "hidden",
          height: "450px",
          width: "400px",
          zIndex: 999,
        }}
      >
        <Map />
      </Col>
      <div className="">
        <p style={{ textAlign: "justify" }}>concreteeng.ltd@gmail.com</p>
      </div>
    </Row>
  );
};

export default About;
