import React from "react";
import { Carousel, Container } from "react-bootstrap";
import Image1 from "../../images/Profile CIEL.soft_page-0005.jpg";
import Image2 from "../../images/Profile CIEL.soft_page-0009.jpg";
import Image3 from "../../images/Profile CIEL.soft_page-0015.jpg";
import Image4 from "../../images/Profile CIEL.soft_page-0016.jpg";
import Image5 from "../../images/Profile CIEL.soft_page-0017.jpg";
import Image6 from "../../images/Profile CIEL.soft_page-0020.jpg";
import "./style.css";  

const MajorProject = () => {
  return (
    <>
    {/* <Navigation/> */}
    <Container id="project" name="project" className="mt-0 body text-center">
      <h1 className="text-center text m-0">Most Successfully Projects</h1>
      <Carousel className="custom-carousel">
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image4} alt="Fourth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image5} alt="Fifth slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 custom-image" src={Image6} alt="Fifth slide" />
        </Carousel.Item>
      
      </Carousel>
    </Container>
    </>
  );
};

export default MajorProject;
