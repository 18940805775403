import AOS from "aos";
import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";
import pic from "../../images/about pic.jpeg";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <HeaderWrapper id="about">
      <Row id="about" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1600">
        <Col md={6} className="d-flex  justify-content-start m-0">
          <Row>
            <Col md={12}>
              <div className="text-start">
                <div>
                  <h5
                    className=" d-inline-block py-2 mb-3  px-4"
                    style={{
                      fontWeight: 450,
                      fontSize: "27px",
                      color: "#045FA6",
                      borderLeft: "4px solid #045FA6",
                      whiteSpace: "nowrap",
                    }}
                  >
                    About Us
                  </h5>
                </div>
                <p  style={{ textAlign: "justify",fontSize:"18px" }}>
                  Concrete Infrastructure Engineering Co.,Ltd goes beyond the
                  traditional scopes of private property development and
                  Government foreign projects under local & foreign clients the
                  best services has to offer.We have become the industry
                  standard for attractive architecture, attention to details
                  construction, superior management and are more cost effective
                  than many of our competitors as depicts our growing portfolio
                  of projects with fully satisfied clients. Our company is built
                  on a solid foundation of brilliant staff, examining in minute
                  detail every aspects of the projects we are assigned.
                </p>
                <p style={{ textAlign: "justify",fontSize:"18px" }} className=" mt-3">
                  Concrete Infrastructure Engineering Co.,Ltd is Specialized is
                  the designing, planning with build Construction, Concrete
                  Solution, Concrete Batching Plant Operation & Management,
                  Steel structure erection & installation, Civil Construction,
                  Concrete repair & application and various dimensions with high
                  quality finish. We also have first-class system for the
                  management of projects, fine harmonization between office &
                  site agent. Our team has always met its Deadlines and
                  sometimes even fared beyond the expectations of our clients,
                  accomplished due to a high level of workmanship.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="back-style">
          <div className=" text-center mx-4">
            <Image
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
              src={pic}
              alt="header_img"
              width='100%'
             height='100%'
            />
          </div>
          <div>
            <Link to="contact">
              {" "}
              <h2
                style={{
                  maxWidth: "285px",
                  fontSize: "37px",
                  fontWeight: 400,
                  color: "#045FA6",
                  borderLeft: "18px solid #045FA6",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                }}
                className="text-center  mx-auto py- my-5"
              >
                Contact
              </h2>
            </Link>
          </div>
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  overflow-x: hidden;
  /* height: 100vh; */
  /* border:1px solid; */
  /* padding:33px; */
  margin-top: 23px;
  // .back-style {
  //     position:relative;
  //     &::before {
  //         content:'';
  //         position:absolute;
  //         top:0;
  //         right:0;
  //         width:55%;
  //         height:100%;
  //         background-color:#F5F5F5;
  //         z-index:-1;
  //     }
  // }
`;

export default About;
